import { Product } from 'shared/constants';
import { Brand } from 'shared/types';

const volkswagenConfig = {
  [Product.DETAILED_COMPARATOR]: {
    hasPhevTestType: true,
    hasEVTestType: true,
    features: {
      currentMileage: { shouldShowCurrentMileageInput: true },
      shopLinkCTA: {
        isShopCTAShown: true,
        makeShopLink: ({
          manufacturer,
          model,
          removeOffers,
        }: {
          manufacturer: string;
          model: string;
          removeOffers?: boolean;
        }) => {
          if (manufacturer?.toLowerCase?.() !== Brand.VOLKSWAGEN) {
            return '';
          }

          const removeOffersFromUrl = (removeOffers ?? false) || new Date() >= new Date('10/01/2024');

          const getShopModel = (model: string): string => {
            const displayModels = [
              ['arteon diesel fastback', 'arteon'],
              ['arteon diesel shooting brake', 'arteon-shooting-brake'],
              ['arteon shooting brake', 'arteon-shooting-brake'],
              ['golf diesel estate', 'golf-estate'],
              ['golf estate', 'golf-estate'],
              ['golf', 'golf'],
              [/id\.3/, 'id3'],
              [/id\.4/, 'id4'],
              [/id\.5/, 'id5'],
              [/id\.7 hatchback/, 'id7'],
              [/id\.7/, 'id7-tourer'],
              ['passat', 'passat'],
              ['polo', 'polo'],
              ['t-cross', 't-cross'],
              ['t-roc cabriolet', 't-roc-cabriolet'],
              ['t-roc', 't-roc'],
              ['taigo', 'taigo'],
              ['tiguan allspace', 'tiguan-allspace'],
              ['tiguan', 'tiguan'],
              ['touareg', 'touareg'],
              ['touran', 'touran'],
              ['up', 'up'],
            ];

            for (let i = 0; i < displayModels.length; i += 1) {
              const displayModel = displayModels[i];

              if (new RegExp(displayModel[0]).test(model.toLowerCase())) {
                return displayModel[1].toString();
              }
            }

            return model.toLowerCase();
          };

          const displayModel = getShopModel(model);

          return `https://www.volkswagen.co.uk/en/new/${displayModel}.html?campaign=DRIVVN&language=EN&adchan=affi&publisher=DRVN&country=GB${
            removeOffersFromUrl ? '' : '#offers'
          }`;
        },
      },
    },
  },
  [Product.USED_COMPARATOR]: { hasPhevTestType: true },
  [Product.ALL]: {
    confirmVehicle: {
      hideIcon: true,
    },
    content: {
      footer: {
        line1: {
          here: 'https://www.volkswagen.co.uk/en/electric-and-hybrid/should-you-go-electric/servicing/battery-maintenance-and-waranty.html',
        },
      },
    },
  },
};

export default volkswagenConfig;
